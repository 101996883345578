/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AccessLog } from '../models/access-log';
import { AccessLogListResp } from '../models/access-log-list-resp';

/**
 * API of AccessLog
 */
@Injectable({
  providedIn: 'root',
})
class AccessLogService extends __BaseService {
  static readonly postApiAccessLogPath = '/api/accessLog';
  static readonly postApiAccessLogListPath = '/api/accessLog/list';
  static readonly putApiAccessLogIdPath = '/api/accessLog/{id}';
  static readonly deleteApiAccessLogIdPath = '/api/accessLog/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * アクセスログの保存
   * @param body undefined
   * @return response of POST /api/accessLog
   */
  postApiAccessLogResponse(body: AccessLog): __Observable<__StrictHttpResponse<AccessLog>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/accessLog`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccessLog>;
      })
    );
  }
  /**
   * アクセスログの保存
   * @param body undefined
   * @return response of POST /api/accessLog
   */
  postApiAccessLog(body: AccessLog): __Observable<AccessLog> {
    return this.postApiAccessLogResponse(body).pipe(
      __map(_r => _r.body as AccessLog)
    );
  }

  /**
   * アクセスログのリスト取得
   * @param body undefined
   * @return response of POST /api/accessLog/list
   */
  postApiAccessLogListResponse(body: AccessLog): __Observable<__StrictHttpResponse<AccessLogListResp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/accessLog/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccessLogListResp>;
      })
    );
  }
  /**
   * アクセスログのリスト取得
   * @param body undefined
   * @return response of POST /api/accessLog/list
   */
  postApiAccessLogList(body: AccessLog): __Observable<AccessLogListResp> {
    return this.postApiAccessLogListResponse(body).pipe(
      __map(_r => _r.body as AccessLogListResp)
    );
  }

  /**
   * アクセスログの修正
   * @param params The `AccessLogService.PutApiAccessLogIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * @return response of PUT /api/accessLog/{id}
   */
  putApiAccessLogIdResponse(params: AccessLogService.PutApiAccessLogIdParams): __Observable<__StrictHttpResponse<AccessLog>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/accessLog/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccessLog>;
      })
    );
  }
  /**
   * アクセスログの修正
   * @param params The `AccessLogService.PutApiAccessLogIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * @return response of PUT /api/accessLog/{id}
   */
  putApiAccessLogId(params: AccessLogService.PutApiAccessLogIdParams): __Observable<AccessLog> {
    return this.putApiAccessLogIdResponse(params).pipe(
      __map(_r => _r.body as AccessLog)
    );
  }

  /**
   * アクセスログの削除
   * @param id undefined
   * @return response of DELETE /api/accessLog/{id}
   */
  deleteApiAccessLogIdResponse(id: string): __Observable<__StrictHttpResponse<AccessLog>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/accessLog/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccessLog>;
      })
    );
  }
  /**
   * アクセスログの削除
   * @param id undefined
   * @return response of DELETE /api/accessLog/{id}
   */
  deleteApiAccessLogId(id: string): __Observable<AccessLog> {
    return this.deleteApiAccessLogIdResponse(id).pipe(
      __map(_r => _r.body as AccessLog)
    );
  }
}

module AccessLogService {

  /**
   * Parameters for putApiAccessLogId
   */
  export interface PutApiAccessLogIdParams {
    id: string;
    body: AccessLog;
  }
}

export { AccessLogService }
