import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ContentService} from '../api/services';
import {MatDialog} from '@angular/material';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {Session, SessionService} from '../session.service';

@Component({
  selector: 'adfileupload-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  id: string;
  password = new FormControl('', Validators.required);
  hide: true;

  constructor(private router: Router, private contentService: ContentService,
              private activatedRoute: ActivatedRoute,
              private sessionService: SessionService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
    });
  }

  onSubmit() {
    this.contentService.postApiContentGet({
      num: this.id,
      password: this.password.value,
    }).subscribe(() => {

      const session: Session = {
        password: this.password.value,
      };

      this.sessionService.add(this.id, session);
      this.router.navigate(['/view', this.id, 'urls']);
    }, (error) => {
      this.dialog.open(MessageDialogComponent, {
        width: '250px',
        data: error.error.text
      });
    });
  }

}
