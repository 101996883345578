/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { History } from '../models/history';
import { HistoryListResp } from '../models/history-list-resp';

/**
 * API of History
 */
@Injectable({
  providedIn: 'root',
})
class HistoryService extends __BaseService {
  static readonly postApiHistoryGetSignedUrlPath = '/api/history/getSignedUrl';
  static readonly postApiHistoryListPath = '/api/history/list';
  static readonly putApiHistoryIdPath = '/api/history/{id}';
  static readonly deleteApiHistoryIdPath = '/api/history/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * ファイル履歴ごとの署名付きURL取得
   * @param body undefined
   * @return response of POST /api/history/getSignedUrl
   */
  postApiHistoryGetSignedUrlResponse(body: History): __Observable<__StrictHttpResponse<History>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/history/getSignedUrl`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<History>;
      })
    );
  }
  /**
   * ファイル履歴ごとの署名付きURL取得
   * @param body undefined
   * @return response of POST /api/history/getSignedUrl
   */
  postApiHistoryGetSignedUrl(body: History): __Observable<History> {
    return this.postApiHistoryGetSignedUrlResponse(body).pipe(
      __map(_r => _r.body as History)
    );
  }

  /**
   * ファイル履歴のリスト取得
   * @param body undefined
   * @return response of POST /api/history/list
   */
  postApiHistoryListResponse(body: History): __Observable<__StrictHttpResponse<HistoryListResp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/history/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HistoryListResp>;
      })
    );
  }
  /**
   * ファイル履歴のリスト取得
   * @param body undefined
   * @return response of POST /api/history/list
   */
  postApiHistoryList(body: History): __Observable<HistoryListResp> {
    return this.postApiHistoryListResponse(body).pipe(
      __map(_r => _r.body as HistoryListResp)
    );
  }

  /**
   * ファイル履歴の修正
   * @param params The `HistoryService.PutApiHistoryIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * @return response of PUT /api/history/{id}
   */
  putApiHistoryIdResponse(params: HistoryService.PutApiHistoryIdParams): __Observable<__StrictHttpResponse<History>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/history/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<History>;
      })
    );
  }
  /**
   * ファイル履歴の修正
   * @param params The `HistoryService.PutApiHistoryIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * @return response of PUT /api/history/{id}
   */
  putApiHistoryId(params: HistoryService.PutApiHistoryIdParams): __Observable<History> {
    return this.putApiHistoryIdResponse(params).pipe(
      __map(_r => _r.body as History)
    );
  }

  /**
   * ファイル履歴の削除
   * @param params The `HistoryService.DeleteApiHistoryIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * @return response of DELETE /api/history/{id}
   */
  deleteApiHistoryIdResponse(params: HistoryService.DeleteApiHistoryIdParams): __Observable<__StrictHttpResponse<History>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/history/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<History>;
      })
    );
  }
  /**
   * ファイル履歴の削除
   * @param params The `HistoryService.DeleteApiHistoryIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * @return response of DELETE /api/history/{id}
   */
  deleteApiHistoryId(params: HistoryService.DeleteApiHistoryIdParams): __Observable<History> {
    return this.deleteApiHistoryIdResponse(params).pipe(
      __map(_r => _r.body as History)
    );
  }
}

module HistoryService {

  /**
   * Parameters for putApiHistoryId
   */
  export interface PutApiHistoryIdParams {
    id: string;
    body: History;
  }

  /**
   * Parameters for deleteApiHistoryId
   */
  export interface DeleteApiHistoryIdParams {
    id: string;
    body?: History;
  }
}

export { HistoryService }
