import {Component, OnInit} from '@angular/core';
import {AccessLogService, ContentService, HistoryService} from '../api/services';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {History} from '../api/models/history';
import {Content} from '../api/models';
import {Session, SessionService} from '../session.service';

@Component({
  selector: 'adfileupload-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  content: Content;
  displayedColumns: string[] = ['num', 'rev', 'file', 'count', 'expires', 'uploaded', 'status'];
  dataSource: History[] = [];
  num: string;
  errorMessage: string;
  isAccessed = false;
  isDisplay = false;


  constructor(private historyService: HistoryService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private contentService: ContentService,
              private accessLogService: AccessLogService,
              private sessionService: SessionService) {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.num = params.get('id');

      const session = this.sessionService.fetch(this.num);
      if (!session) {
        this.router.navigate(['/view', this.num]);
      }

      this.historyService.postApiHistoryList({
        num: this.num,
        password: session.password
      }).subscribe(
        (success) => {
          this.dataSource = success.list;

          this.dataSource.map(h => {
            if (h.downloadCount == null) {
              h.downloadCount = 0;
            }
          });
        },
        () => this.router.navigate(['/view', this.num])
      );
    });

  }


  ngOnInit() {
  }


  getList() {
    this.historyService.postApiHistoryList({
      num: this.num,
      password: this.getSession().password
    }).subscribe(
      (history) => this.dataSource = history.list,
      () => this.router.navigate(['/view', this.num])
    );
  }

  downloadFileFromSignedUrl(history: History) {
    // 署名付きURLの取得
    this.historyService.postApiHistoryGetSignedUrl({
      id: history.id,
      num: this.num,
      password: this.getSession().password,
    }).subscribe((success) => {

        this.downloadFile(success.url, history.fileName);
        this.getList();

        // 同じsession内では すでにdownloadしたことがある場合は 記録しない。
        const session = this.getSession();
        if (session.url != null && history.rev === session.rev) {
          return;
        }

        // sessionに 署名付きURLを記録。
        session.url = success.url;
        session.rev = history.rev;
        this.sessionService.add(this.num, session);


        // download回数を追加し保存
        if (history.downloadCount == null) {
          // go lang仕様で intの0はnull扱いのため、0を入れ直す
          history.downloadCount = 0;
        }

        history.downloadCount = history.downloadCount + 1;
        history.password = this.getSession().password;
        this.historyService.putApiHistoryId({
          id: history.id,
          body: history
        }).subscribe();


        // access履歴を追加
        this.accessLogService.postApiAccessLog({
            num: this.num,
            fileName: history.fileName,
            rev: history.rev,
            password: this.getSession().password
          },
        ).subscribe(() => {
          // accessLogComponentに 通達
          this.isAccessed = true;
        });
      },
      (err) => {
        this.errorMessage = err.error.text;
        this.getList();
      });

  }

  onAccess(history: History) {
    const session = this.getSession();
    if (session.url != null && history.rev === session.rev) {
      this.downloadFile(session.url, history.fileName);
      return;
    }

    this.downloadFileFromSignedUrl(history);
  }

  getSession(): Session {
    const session = this.sessionService.fetch(this.num);
    if (!session) {
      this.router.navigate(['/view', this.num]);
    }

    return session;
  }

  downloadFile(url: string, fileName: string) {
    (async () => {
      // fetchでアクセス.
      const res = await fetch(url);
      // Blob形式でデータ取得.
      const blob = await res.blob();
      window.URL.createObjectURL(blob);


      // aタグを作成してクリック -> ダウンロード機能発火
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);

    })();

  }

}
