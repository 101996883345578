import {enableProdMode, LOCALE_ID} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const localeProviders = (() => {
  // ブラウザの言語を取得
  let browserLanguage: string = (() => {
    if (navigator.languages.length > 0) {
      return navigator.languages[0];
    }
    if (navigator.language) {
      return navigator.language;
    }
    return 'en-US';
  })();

  const allowLanguages = [
    'ja',
    'en-US',
    'zh',
    'zh-CN',
    'zh-HK',
    'zh-TW',
    'zh-Hans-HK',
    'zh-Hans-MO',
    'zh-Hans-SG',
    'zh-Hans',
    'zh-Hant-HK',
    'zh-Hant-MO',
    'zh-Hant',
  ];


  if (!allowLanguages.some(value => value === browserLanguage)) {
    browserLanguage = 'en-US';
  }


  return [
    {provide: LOCALE_ID, useValue: browserLanguage}
  ];

})();

platformBrowserDynamic(localeProviders).bootstrapModule(AppModule)
  .catch(err => console.log(err));

