/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Content } from '../models/content';
import { ContentListResp } from '../models/content-list-resp';

/**
 * API of Content
 */
@Injectable({
  providedIn: 'root',
})
class ContentService extends __BaseService {
  static readonly postApiContentPath = '/api/content';
  static readonly postApiContentGetPath = '/api/content/get';
  static readonly postApiContentListPath = '/api/content/list';
  static readonly putApiContentNumPath = '/api/content/{num}';
  static readonly deleteApiContentNumPath = '/api/content/{num}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * ファイル情報の保存
   * @param body undefined
   * @return response of POST /api/content
   */
  postApiContentResponse(body: Content): __Observable<__StrictHttpResponse<Content>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/content`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Content>;
      })
    );
  }
  /**
   * ファイル情報の保存
   * @param body undefined
   * @return response of POST /api/content
   */
  postApiContent(body: Content): __Observable<Content> {
    return this.postApiContentResponse(body).pipe(
      __map(_r => _r.body as Content)
    );
  }

  /**
   * ファイルの取得
   * @param body undefined
   * @return response of POST /api/content/get
   */
  postApiContentGetResponse(body: Content): __Observable<__StrictHttpResponse<Content>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/content/get`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Content>;
      })
    );
  }
  /**
   * ファイルの取得
   * @param body undefined
   * @return response of POST /api/content/get
   */
  postApiContentGet(body: Content): __Observable<Content> {
    return this.postApiContentGetResponse(body).pipe(
      __map(_r => _r.body as Content)
    );
  }

  /**
   * ファイル情報のリスト取得
   * @param body undefined
   * @return response of POST /api/content/list
   */
  postApiContentListResponse(body: Content): __Observable<__StrictHttpResponse<ContentListResp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/content/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContentListResp>;
      })
    );
  }
  /**
   * ファイル情報のリスト取得
   * @param body undefined
   * @return response of POST /api/content/list
   */
  postApiContentList(body: Content): __Observable<ContentListResp> {
    return this.postApiContentListResponse(body).pipe(
      __map(_r => _r.body as ContentListResp)
    );
  }

  /**
   * ファイル情報の修正
   * @param params The `ContentService.PutApiContentNumParams` containing the following parameters:
   *
   * - `num`:
   *
   * - `body`:
   *
   * @return response of PUT /api/content/{num}
   */
  putApiContentNumResponse(params: ContentService.PutApiContentNumParams): __Observable<__StrictHttpResponse<Content>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/content/${params.num}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Content>;
      })
    );
  }
  /**
   * ファイル情報の修正
   * @param params The `ContentService.PutApiContentNumParams` containing the following parameters:
   *
   * - `num`:
   *
   * - `body`:
   *
   * @return response of PUT /api/content/{num}
   */
  putApiContentNum(params: ContentService.PutApiContentNumParams): __Observable<Content> {
    return this.putApiContentNumResponse(params).pipe(
      __map(_r => _r.body as Content)
    );
  }

  /**
   * ファイル情報の削除
   * @param params The `ContentService.DeleteApiContentNumParams` containing the following parameters:
   *
   * - `num`:
   *
   * - `body`:
   *
   * @return response of DELETE /api/content/{num}
   */
  deleteApiContentNumResponse(params: ContentService.DeleteApiContentNumParams): __Observable<__StrictHttpResponse<Content>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/content/${params.num}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Content>;
      })
    );
  }
  /**
   * ファイル情報の削除
   * @param params The `ContentService.DeleteApiContentNumParams` containing the following parameters:
   *
   * - `num`:
   *
   * - `body`:
   *
   * @return response of DELETE /api/content/{num}
   */
  deleteApiContentNum(params: ContentService.DeleteApiContentNumParams): __Observable<Content> {
    return this.deleteApiContentNumResponse(params).pipe(
      __map(_r => _r.body as Content)
    );
  }
}

module ContentService {

  /**
   * Parameters for putApiContentNum
   */
  export interface PutApiContentNumParams {
    num: string;
    body: Content;
  }

  /**
   * Parameters for deleteApiContentNum
   */
  export interface DeleteApiContentNumParams {
    num: string;
    body?: Content;
  }
}

export { ContentService }
