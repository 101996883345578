import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {HistoryComponent} from './history/history.component';

const routes: Routes = [
  {path: 'view/:id', component: LoginComponent},
  {path: 'view/:id/urls', component: HistoryComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
