import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderComponent} from './header/header.component';
import {LoginComponent} from './login/login.component';
import {AccesslogComponent} from './accesslog/accesslog.component';
import {HistoryComponent} from './history/history.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatButtonModule,
  MatCardModule, MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatTableModule
} from '@angular/material';
import {ApiModule} from './api/api.module';
import {MessageDialogComponent} from './message-dialog/message-dialog.component';

import localeJa from '@angular/common/locales/ja';
import localeZhHangHK from '@angular/common/locales/zh-Hans-HK';
import localeZhHangMO from '@angular/common/locales/zh-Hans-MO';
import localeZhHangSG from '@angular/common/locales/zh-Hans-SG';
import localeZhHang from '@angular/common/locales/zh-Hans';
import localeZhHantHK from '@angular/common/locales/zh-Hant-HK';
import localeZhHantMO from '@angular/common/locales/zh-Hant-MO';
import localeZhHant from '@angular/common/locales/zh-Hant';
import localeZh from '@angular/common/locales/zh';

import {registerLocaleData} from '@angular/common';

registerLocaleData(localeJa);
registerLocaleData(localeZhHangHK);
registerLocaleData(localeZhHangMO);
registerLocaleData(localeZhHangSG);
registerLocaleData(localeZhHang);
registerLocaleData(localeZhHantHK);
registerLocaleData(localeZhHantMO);
registerLocaleData(localeZhHant);
registerLocaleData(localeZh);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    AccesslogComponent,
    HistoryComponent,
    MessageDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ApiModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatDialogModule
  ],
  entryComponents: [
    MessageDialogComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
