import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {AccessLogService} from '../api/services';
import {AccessLog} from '../api/models';
import {SessionService} from '../session.service';

@Component({
  selector: 'adfileupload-accesslog',
  templateUrl: './accesslog.component.html',
  styleUrls: ['./accesslog.component.scss']
})

export class AccesslogComponent implements OnInit {

  num: string;
  displayedColumns: string[] = ['accessed', 'rev', 'fileName', 'sourceIP', 'country'];
  dataSource: AccessLog[];

  private _isAccessed = false;

  @Input()
  set isAccessed(isAccessed: boolean) {
    this._isAccessed = isAccessed;

    if (isAccessed === true) {
      this.getAccessLog();
    }
  }

  get isAccessed(): boolean {
    return this._isAccessed;
  }

  constructor(private accessLogService: AccessLogService,
              private activatedRoute: ActivatedRoute,
              private sessionService: SessionService) {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.num = params.get('id');
      this.getAccessLog();
    });

  }

  ngOnInit() {
  }

  getAccessLog() {
    const session = this.sessionService.fetch(this.num);
    if (!session) {
      return;
    }


    this.accessLogService.postApiAccessLogList({
      num: this.num,
      password: session.password
    }).subscribe((resp) => this.dataSource = resp.list);

  }
}
