import {Injectable} from '@angular/core';

export interface Session {
  password: any;
  url?: string;
  rev?: number;
  expire?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  SESSION_TIME = 60;

  constructor() {
  }

  fetch(num: string): Session {
    let session: Session;
    session = JSON.parse(localStorage.getItem(num));

    if (session == null) {
      return;
    }

    if (new Date(session.expire) < new Date()) {
      localStorage.removeItem(num);
      return;
    }

    return session;
  }

  add(num: string, session: Session) {
    const dt = new Date();

    // sessionの時間を指定
    dt.setMinutes(dt.getMinutes() + this.SESSION_TIME);
    session.expire = dt;

    localStorage.setItem(num, JSON.stringify(session));
  }
}
